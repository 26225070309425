angular.module('app')
    .filter('bookingDateTime', ["$filter", "moment", function ($filter, moment) {
        return function (dateTime, format, defaultValue) {

            if (angular.isUndefined(dateTime)) return '';

            var calendar = moment.isMoment(dateTime) ? moment(dateTime).calendar() : moment(dateTime).utcOffset(dateTime).calendar();

            if (calendar.indexOf('Yesterday') === 0 || calendar.indexOf('Today') === 0) {
                return calendar;
            }

            return moment.isMoment(dateTime) ? dateTime.format('DD/MM/YYYY h:mm A') : moment(dateTime).utcOffset(dateTime).format('DD/MM/YYYY h:mm A');


        };
    }]);